.story-cont {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url("../../Assets/bg-2.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  margin-top: -4px;
  padding: 2.5rem 0rem;
}
.story {
  max-width: 950px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px;
  gap: 20px;
  border-radius: 15px;
}

.heading {
  max-width: 100%;
  object-fit: contain;
}
.story-div {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  list-style-image: url("../../Assets/star-solid.png");
  gap: 35px;
}
.story-div li {
  font-size: 1.3rem;
  line-height: 1.6;
  text-align: center;
}
.story-div li::marker {
  color: red;
}
.story-div p {
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.story-div p strong {
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}
.story-div a {
  align-self: center;
}
.load {
  align-self: center;
  /* font-size: 2rem; */
  background-color: #a14200;
  color: white;
  width: 30px;
  height: 30px;
  padding: 10px;
  text-decoration: none;
  cursor: pointer;
  border-radius: 50%;
}
@media screen and (max-width: 972px) {
  .story {
    max-width: 95%;
  }
}
@media screen and (max-width: 548px) {
  .story h1 {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 404px) {
  .story h1 {
    font-size: 2.2rem;
  }
}
