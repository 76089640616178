body {
  background-color: #d9c58a;
}
.App {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.line-img {
  max-width: 100%;
  width: 100%;
  height: 15px;
  background-color: #4b2f00;
  opacity: 0.8;
}
.orange {
  background-color: #de6e00;
}
