.util-cont {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url("../../Assets/Role/background.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  margin-top: -4px;
}
.util {
  width: 100%;
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 1700px;
  height: auto;
  position: relative;
}
.util-img {
  max-width: 100%;
  width: 100%;
  object-fit: contain;
}
.card {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  height: max-content;
  text-align: left;
}
.card .story-div {
  list-style-position: inside;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  max-width: 505px;
}
.card h2 {
  font-size: 1.6rem;
  color: #b04335;
}

.card1 {
  left: 5%;
  top: 7%;
}
.card2 {
  left: 63%;
  top: 7%;
}
.card3 {
  left: 3%;
  top: 42%;
}
.card4 {
  left: 71%;
  top: 44%;
}
.card5 {
  top: 77%;
  left: 12%;
}
.card6 {
  top: 77%;
  left: 62%;
}
.line-util {
  display: none;
  max-width: 100%;
  object-fit: contain;
}
.mobile-circle {
  display: none;
}
.card .story-div li {
  font-size: 1rem;
}
@media screen and (max-width: 1540px) {
  .card1 {
    left: 2%;
  }
}
@media screen and (max-width: 1430px) {
  .card1 {
    left: 5%;
    width: 500px;
    top: 2%;
  }
  .card2 {
    top: 2%;
  }
}
@media screen and (max-width: 1300px) {
  .card1 {
    left: 0;
  }
  .card3 {
    left: 1%;
  }
  .card5 {
    left: 8%;
  }
}
@media screen and (max-width: 1336px) {
  /* .card .story-div li {
    font-size: 1.1rem;
  } */
  .card h2 {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 1224px) {
  .util-cont {
    padding: 2rem 0rem;
  }
  .card1 {
    top: -1%;
  }
  .card2 {
    top: -1%;
  }
}

@media screen and (max-width: 1100px) {
  .mobile-circle {
    max-width: 200px;
    object-fit: contain;
    display: block;
    grid-column: 2/3;
    grid-row: 1/4;
  }
  .line-util {
    display: block;
  }
  .util-img {
    display: none;
  }
  .card {
    position: static;
    max-width: max-content;
  }
  .util {
    padding: 0rem 2rem;
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 200px 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
  .card1,
  .card3,
  .card5 {
    width: auto;
    grid-column: 1/2;
  }
  .card2,
  .card4,
  .card6 {
    grid-column: 3/4;
  }
  /* .card .story-div li {
    font-size: 1.1rem;
  } */
  .card h2 {
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 1100px) {
  .util {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
  }
  .util-cont {
    padding: 3rem 0rem;
  }
  .mobile-circle {
    max-width: 350px;
  }
  /* .card .story-div li {
    font-size: 1.25rem;
  } */
  .card {
    text-align: center;
  }
  .card h2 {
    font-size: 1.45rem;
  }
}
@media screen and (max-width: 560px) {
  .mobile-circle {
    max-width: 60%;
  }
}
