.navbar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000000000;
  transition: all 0.2s ease;
  padding: 1rem 0;
}
.container-wrapper {
  max-width: 1800px;
  width: 90%;
  margin: 0 auto;
}
.h_logo {
  width: 240px;
}

.navbar-wrapper.scrolled {
  background-color: #e2cfa5;
  box-shadow: 10px 0 10px rgba(0, 0, 0, 0.4);
  padding: 0.6rem 0;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border: 2px solid red; */
}

.hamburger {
  display: none;
  cursor: pointer;
}

.navbar-left {
  display: flex;
  align-items: center;
  margin-right: 85px;
}

.navbar-right {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.nav-links {
  display: flex;

  align-items: center;
}
.nav-link {
  text-decoration: none;
  color: #04060f;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}
.nav-link:not(:last-child) {
  margin-right: 36px;
}

.nav-btn {
  width: 190px;
  height: 55px;
  background: #272722;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  color: #04060f;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  align-items: center;
  font-size: 1.2rem;
  text-decoration: none;
  color: white;
}

.cross-icon {
  display: none;
  width: 18px;
  height: 18px;
}
.icon-head {
  color: #d9c58a !important;
  background-color: white !important;
}
@media (max-width: 1124px) {
  /* .h_logo {
    width: 65px;
  } */
  .hamburger {
    display: block;
    cursor: pointer;
  }
  .navbar {
    justify-content: flex-end;
  }
  .navbar-wrapper {
    height: 111px;
  }
  .container-wrapper {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .navbar-left {
    margin: 0;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }

  .navbar-right {
    position: fixed;
    top: 0;
    right: -300px;
    width: 300px;
    height: 100%;
    flex-direction: column;
    justify-content: unset;
    align-items: unset;
    box-shadow: 0 -10px 10px rgba(0, 0, 0, 0.4);
    z-index: 100000000000;
    background-color: #e2cfa5;
    flex-direction: column;
    padding: 2.5rem;

    transition: all 0.2s ease;
  }

  .navbar-right.show {
    right: 0;
  }

  .nav-links {
    flex-direction: column;
    justify-content: unset;
    align-items: unset;
  }

  .nav-link:not(:last-child) {
    margin-right: 0rem;
    margin-bottom: 2rem;
  }
  .nav-link {
    margin-bottom: 2rem;
  }

  .cross-icon {
    display: block;
    position: relative;
    z-index: 100000;
    cursor: pointer;
    margin-bottom: 2rem;
  }
}
