.roadmap-cont {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-image: url("../../Assets/Roadmap/RMbackground.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  padding-top: 2.5rem;
}
.roadmap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  max-width: 1800px;
  position: relative;
  height: auto;
  z-index: 10;
  gap: 3rem;
}
.roadmap-div {
  width: 1256px;
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-template-rows: 1fr auto 1fr;
  margin-top: 3rem;
  position: relative;
  z-index: 2;
}

.circle-road {
  max-width: 300px;
  object-fit: contain;
  position: relative;
  z-index: 4;
}
.wrapper-img {
  position: relative;
}
.circle-road-div1,
.circle-road-div2,
.circle-road-div3,
.circle-road-div4,
.circle-road-div5,
.circle-road-div6,
.circle-road-div7 {
  position: relative;
  align-self: center;
  justify-self: center;
}
.abs-text-road {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  font-size: 1.1rem;
  width: 200px;
  text-align: center;
}
.circle-road-div1 {
  grid-column: 1/2;
  grid-row: 1/2;
}

.circle-road-div1::before,
.circle-road-div4::before,
.circle-road-div6::before {
  content: "";
  position: absolute;
  width: 8px;
  background-color: #a6510e;
  z-index: 3;
  right: 0;
  height: 335px;
  border-radius: 25px;
  left: 46%;
  transform: rotate(0deg);
  top: 96%;
}

.circle-road-div2 {
  grid-column: 1/2;
  grid-row: 3/4;
}
.circle-road-div2::before {
  content: "";
  position: absolute;
  width: 8px;
  background-color: #a6510e;
  z-index: 3;
  right: 0;
  height: 50%;
  border-radius: 25px;
  left: 102%;
  transform: rotate(35deg);
  top: -24%;
}
.circle-road-div3 {
  grid-column: 2/3;
  grid-row: 2/3;
}
.circle-road-div3::before {
  content: "";
  position: absolute;
  width: 8px;
  background-color: #a6510e;
  z-index: 3;
  right: 0;
  height: 50%;
  border-radius: 25px;
  left: 95%;
  transform: rotate(38deg);
  top: -33%;
}
.circle-road-div4 {
  grid-column: 3/4;
  grid-row: 1/2;
}

.circle-road-div5 {
  grid-column: 3/4;
  grid-row: 3/4;
}
.circle-road-div5::before {
  content: "";
  position: absolute;
  width: 8px;
  background-color: #a6510e;
  z-index: 3;
  right: 0;
  height: 425px;
  border-radius: 25px;
  left: 98%;
  transform: rotate(25deg);
  top: -122%;
}
.circle-road-div6 {
  grid-column: 4/5;
  grid-row: 1/2;
  margin-left: 2rem;
}

.circle-road-div7 {
  grid-column: 4/5;
  grid-row: 3/4;
  margin-left: 2rem;
}

.steak {
  position: absolute;
  max-width: 100%;
  object-fit: contain;
  bottom: 0;
  right: 0;
  z-index: -1;
}
@media screen and (max-width: 1256px) {
  .roadmap-div {
    width: 70%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1.5fr 1fr 1.5fr 1fr 1.5fr;
    gap: 3rem;
    margin-top: 3rem;
    position: relative;
    z-index: 2;
  }

  .circle-road {
    max-width: 300px;
    object-fit: contain;
    position: relative;
    z-index: 4;
  }

  .wrapper-img {
    position: relative;
  }

  .circle-road-div1,
  .circle-road-div2,
  .circle-road-div3,
  .circle-road-div4,
  .circle-road-div5,
  .circle-road-div6,
  .circle-road-div7 {
    position: relative;
  }

  .circle-road-div1 {
    grid-column: 1/2;
    grid-row: 1/2;
    justify-self: flex-end;
    align-self: flex-start;
  }

  .circle-road-div1::before {
    content: "";
    position: absolute;
    width: 8px;
    background-color: #a6510e;
    z-index: 3;
    right: 0;
    height: 30%;
    border-radius: 25px;
    left: 106%;
    transform: rotate(-60deg);
    top: 60%;
  }

  .circle-road-div2 {
    grid-column: 2/3;
    grid-row: 1/2;
    justify-self: flex-start;
    align-self: flex-end;
  }

  .circle-road-div2::before {
    content: "";
    position: absolute;
    width: 8px;
    background-color: #a6510e;
    z-index: 3;
    right: 0;
    height: 36%;
    border-radius: 25px;
    left: 14%;
    transform: rotate(35deg);
    top: 87%;
  }

  .circle-road-div3 {
    grid-column: 1/3;
    grid-row: 2/3;
    justify-self: center;
    align-self: center;
  }

  .circle-road-div3::before {
    content: "";
    position: absolute;
    width: 8px;
    background-color: #a6510e;
    z-index: 3;
    right: 0;
    height: 36%;
    border-radius: 25px;
    left: 14%;
    transform: rotate(35deg);
    top: 87%;
  }

  .circle-road-div4 {
    grid-column: 1/2;
    grid-row: 3/4;
    justify-self: flex-end;
    align-self: flex-start;
  }

  .circle-road-div4::before {
    content: "";
    position: absolute;
    width: 8px;
    background-color: #a6510e;
    z-index: 3;
    right: 0;
    height: 30%;
    border-radius: 25px;
    left: 106%;
    transform: rotate(-60deg);
    top: 60%;
  }

  .circle-road-div5 {
    grid-column: 2/3;
    grid-row: 3/4;
    justify-self: flex-start;
    align-self: flex-end;
  }

  .circle-road-div5::before {
    content: "";
    position: absolute;
    width: 8px;
    background-color: #a6510e;
    z-index: 3;
    right: 0;
    height: 36%;
    border-radius: 25px;
    left: 14%;
    transform: rotate(35deg);
    top: 87%;
  }

  .circle-road-div6 {
    grid-column: 1/3;
    grid-row: 4/5;
    justify-self: center;
    align-self: center;
  }

  .circle-road-div6::before {
    content: "";
    position: absolute;
    width: 8px;
    background-color: #a6510e;
    z-index: 3;
    right: 0;
    height: 36%;
    border-radius: 25px;
    left: 14%;
    transform: rotate(35deg);
    top: 87%;
  }

  .circle-road-div7 {
    grid-column: 1/2;
    grid-row: 5/6;
    justify-self: flex-end;
    align-self: flex-start;
  }

  .circle-road-div7::before {
    display: none;
  }
  .steak {
    position: absolute;
    max-width: 70%;
    object-fit: contain;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
}
@media screen and (max-width: 1172px) {
  .steak {
    max-width: 100%;
  }
}
@media screen and (max-width: 959px) {
  .roadmap .heading {
    max-width: 100%;
  }
}
@media screen and (max-width: 800px) {
  .steak {
    max-width: 100%;
  }
}
@media screen and (max-width: 950px) {
  .roadmap-div {
    width: 100%;
  }
}
@media screen and (max-width: 665px) {
  .roadmap-div {
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr;
    gap: 7rem;
    grid-template-rows: none;
  }
  .circle-road-div1 {
    grid-column: 1/2;
    grid-row: 1/2;
    align-self: center;
    justify-self: center;
  }
  .circle-road-div2 {
    grid-column: 1/2;
    grid-row: 2/3;
    align-self: center;
    justify-self: center;
  }
  .circle-road-div3 {
    grid-column: 1/2;
    grid-row: 3/4;
    align-self: center;
    justify-self: center;
  }
  .circle-road-div4 {
    grid-column: 1/2;
    grid-row: 4/5;
    align-self: center;
    justify-self: center;
  }
  .circle-road-div5 {
    grid-column: 1/2;
    grid-row: 5/6;
    align-self: center;
    justify-self: center;
  }
  .circle-road-div6 {
    grid-column: 1/2;
    grid-row: 6/7;
    align-self: center;
    justify-self: center;
  }
  .circle-road-div7 {
    grid-column: 1/2;
    grid-row: 7/8;
    align-self: center;
    justify-self: center;
  }
  .circle-road-div1::before,
  .circle-road-div2::before,
  .circle-road-div3::before,
  .circle-road-div4::before,
  .circle-road-div5::before,
  .circle-road-div6::before {
    width: 4px;
    height: 131px;
    background-color: #a6510e;
    border-radius: 20px;
    bottom: 0;
    left: 50%;
    top: 96%;
    transform: rotate(0deg);
  }
}
@media screen and (max-width: 500px) {
  .roadmap .heading {
    max-width: 100%;
  }
}
