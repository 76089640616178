.team-cont {
  min-height: auto;
  background-image: url("../../Assets/bg-2.png");
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 2.5rem 0rem;
  position: relative;
}
.team {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr auto 1fr;
  width: 100%;
  max-width: 1800px;
  padding: 0rem 2rem;
  position: relative;
  row-gap: 2rem;
  column-gap: 1rem;
}
.abs-h2 {
  grid-column: 1/5;
  grid-row: 2/3;
  font-size: 2rem;
  margin: 2rem 0rem;
  text-align: center;
}
.team a {
  align-self: center;
  justify-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.team-head {
  max-width: 70%;
  z-index: 0;
  margin-bottom: 3rem;
}
.team-img {
  max-width: 320px;
  object-fit: contain;
  pointer-events: pointer;
}

.mob {
  display: none;
}
.desk {
  display: block;
}
@media screen and (max-width: 1374px) {
  .team a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .team-img {
    max-width: 90%;
  }
}
@media screen and (max-width: 840px) {
  .mob {
    display: block;
  }
  .desk {
    display: none;
  }
  .abs-h2 {
    display: none;
  }
  .team {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: none;
    grid-auto-rows: auto;
  }
  .team-head {
    max-width: 100%;
  }
}
