.hero {
  margin-top: 111px;
  min-height: calc(100vh - 111px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-image: url("../../Assets/bg-2.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  gap: 2rem;
  padding-bottom: 2rem;
}
.line-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 7px;
  width: 100%;
}

.color-lines {
  width: 100%;
  height: 25px;
  opacity: 0.75;
  mix-blend-mode: multiply;
}
.line1 {
  background-color: #c10408;
}
.line2 {
  background-color: #e35211;
}
.line3 {
  background-color: #e27a00;
}

.center-hero {
  gap: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.hero-head {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.hero-head span {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.865983893557423) 28%,
    rgba(255, 52, 52, 0.8855917366946778) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 3rem;
  font-weight: 400;
  text-align: center;
}
.hero img {
  max-width: 440px;
  width: 100%;
  object-fit: contain;
  border: 5px solid #4b2f00;
  border-radius: 25px;
}
.social-div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.social-div svg {
  color: white;
  background-color: #d9c58a;
  padding: 10px;
  font-size: 1.5rem;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
}
.social-div svg:hover,
.mint:hover {
  transform: scale(1.2);
}
.hero-bot {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-direction: column;
}
.mint {
  padding: 10px 45px;
  color: white;
  background-color: #e35211;
  border: 2px solid #4b2f00;
  font-size: 1.6rem;
  border-radius: 15px;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
}
.hero-desc {
  width: 800px;
  text-align: center;
  font-size: 1.3rem;
  line-height: 1.7;
}
@media screen and (max-width: 1062px) {
  .hero {
    min-height: calc(100vh - 111px);
    margin-top: 111px;
  }
}
@media screen and (max-width: 966px) {
  .hero-head span {
    font-size: 2.5rem;
  }

  .hero {
    gap: 4rem;
    padding-bottom: 3rem;
    min-height: auto;
  }
}
@media screen and (max-width: 816px) {
  .hero-head span {
    font-size: 2.5rem;
  }
  .hero-desc {
    width: 95%;
  }
}
@media screen and (max-width: 700px) {
  .hero-head span {
    font-size: 2rem;
  }
}
@media screen and (max-width: 548px) {
  .hero-head span {
    font-size: 1.7rem;
  }
}

@media screen and (max-width: 470px) {
  .hero-head span {
    font-size: 1.5rem;
  }

  .color-lines {
    height: 30px;
  }
}
@media screen and (max-width: 440px) {
  .hero img {
    max-width: 100%;
    width: 90%;
    border-radius: 0%;
    object-fit: cover;
  }
}
@media screen and (max-width: 420px) {
  .hero-head span {
    font-size: 1.3rem;
  }
}
