.role-cont {
  min-height: 100vh;
  background-image: url("../../Assets/Role/background.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.role {
  padding: 2rem 0rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr auto 1fr;
  max-width: 60%;
  gap: 1rem;
}
.role-img {
  max-width: 100%;
  cursor: pointer;
  object-fit: contain;
}
.role-h1 {
  text-align: center;
  grid-column: 1/4;
  grid-row: 2/3;
}
.modal {
  position: absolute;
  width: 80%;
  height: auto;
  box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.4);
  border-radius: 20px;
  top: 50%;
  bottom: 0;
  left: 50%;
  right: 0;
  background-image: url("../../Assets/bg-2.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 10;
  padding: 2rem;
  flex-direction: column;
  visibility: hidden;
  pointer-events: none;
  transform: translateY(2rem) translateX(-50%);
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.open-modal {
  visibility: visible;
  transform: translateX(-50%) translateY(-50%);
  opacity: 1;
  pointer-events: all;
}
.modal svg {
  position: absolute;
  right: 5%;
  top: 5%;
  cursor: pointer;
  font-size: 2rem;
}
.modal img {
  width: 100%;
  max-width: 100%;
  object-fit: contain;
}
.modal p {
  padding-left: 1rem;
  font-size: 1.4rem;
}
.anim-round {
  animation: round 5s ease-in-out 0s infinite;
}
@keyframes round {
  25% {
    transform: rotate(15deg);
  }

  75% {
    transform: rotate(-15deg);
  }
}
@media screen and (max-width: 1498px) {
  .role {
    max-width: 85%;
  }
}
@media screen and (max-width: 1366px) {
  .modal {
    width: 90%;
  }
}
@media screen and (max-width: 1012px) {
  .role {
    max-width: 95%;
  }
  .role-cont {
    min-height: auto;
    padding: 2rem 0rem;
  }
  .modal p {
    padding-left: 0px;
  }
  .modal {
    height: max-content;
  }
}
@media screen and (max-width: 808px) {
  .role-h1 {
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 500px) {
  .modal p {
    font-size: 1.1rem;
  }
  .modal svg {
    font-size: 1.6rem;
  }
  .role {
    padding: 0rem;
  }
  .role-h1 {
    font-size: 1rem;
  }
}
@media screen and (max-width: 428px) {
  .modal {
    height: 90%;
  }
}
