@font-face {
  font-family: "Hipeless Brush Regular";
  font-style: normal;
  font-weight: normal;
  src: url("./Assets/HipelessBrush-Yz5eO.woff") format("woff");
}
@import url("https://fonts.googleapis.com/css2?family=Syne+Mono&display=swap");
* {
  margin: 0;
  padding: 0;
  font-family: "Syne Mono", monospace;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
